
import Vue from 'vue'

export default Vue.extend({
  name: 'MonthWeekFilter',
  components: {},
  props: {
    weeklyOrMonthly: {
      type: Array as () => string[],
      default: () => null,
    },
    weeklyOrMontlyTab: {
      type: Number as () => Number,
      default: () => 0,
    },
  },
  data() {
    return {
      filterTab: 0,
      weeklyOrMonthlyTab: {
        WEEKLY: 0,
        MONTHLY: 1,
      } as const,
    }
  },
  methods: {
    weeklyOrMonthlyTabChange(index: number) {
      this.filterTab = index
      this.$emit('weeklyOrMonthlyTabChange', index)
    },
  },
})
