
import Vue from 'vue'
import CategoryDialog from './CategoryDialog.vue'
import MonthWeekFilter from './MonthWeekFilter.vue'
import Sharing from './../../../common/dialogs/Sharing.vue'
import { mapActions, mapState } from 'vuex'
export default Vue.extend({
  components: {
    CategoryDialog,
    MonthWeekFilter,
    Sharing,
  },
  props: {
    handleAnimation: {
      type: Boolean,
      default: () => false,
    },
    weeklyOrMonthly: {
      type: Array as () => string[],
      default: () => null,
    },
    weeklyOrMontlyTab: {
      type: Number as () => number,
      default: () => 0,
    },
    isSaleProduct: {
      type: Boolean as () => boolean,
      default: () => null,
    },
    share: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    copyUrlProps: {
      type: Object as () => object,
      default: () => null,
    },
    shareItem: {
      type: Object as () => object,
      default: () => null,
    },
    previousPage: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    categoryCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      showShare: false,
    }
  },
  computed: {
    ...mapState('DialogStore', ['loginDialog']),
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    handleDialog(yn: boolean, type: string): void {
      if (this.isLogined) {
        this.$router.push('/mypage')
        return
      }

      if (type === 'login') {
        this.fetchLoginDialog({ yn })
        return
      }
    },
    onClickTriangle() {
      if (this.share) {
        return
      }
      this.dialog = !this.dialog
      const flag =
        document.location.href.includes('categoryNewList') ||
        document.location.href.includes('categoryBestList')

      this.$emit('onClickTitle')
      if (flag) {
        this.$emit('onClickTriangle', this.handleAnimation)
      } else if (document.location.href.includes('categoryExhibitionList')) {
        this.$emit('onClickTriangle', !this.share)
      }
    },
    onClickPrevIcon() {
      //기획전 상세에서 뒤로가기 -> 기획전 메인에서 뒤로가기 카테고리 메인으로 가도록 이전 페이지 Query추가
      this.$emit('onClickPrev')
      if (this.$route.query.previousPage === 'categoryExhibitionMain') {
        this.$router.push(
          '/categoryExhibitionMain?categoryName=CELL : TIQUE&previousPage=categoryMain'
        )
        return
      } else if (this.$route.query.previousPage === 'exhibitionEvent') {
        this.$router.push('/exhibitionEvent?shopId=' + this.$route.query.shopId)
        return
      } else if (this.$route.query.previousPage) {
        //카테고리 메인 다이얼로그로 이동시 뒤로가기 이슈 이전 페이지 QUERY로 추가
        this.$router.push(String(this.$route.query.previousPage))
        return
      } else {
        this.$router.back()
      }
    },
    closeDialog() {
      this.dialog = false
    },
    weeklyOrMonthlyTabChange(index: number) {
      this.$emit('weeklyOrMonthlyTabChange', index)
    },
    saleCheckTabChange() {
      this.$emit('saleCheckTabChange', this.isSaleProduct ? 0 : 1)
    },
    shareProduct() {
      this.$emit('openShare')
      this.showShare = !this.showShare
    },
    closeShare(type: string) {
      this.$emit('closeShare', type)
      this.showShare = false
    },
  },
})
