
import Vue from 'vue'
import CategoryMain from '../CategoryMain.vue'
export default Vue.extend({
  name: 'CategoryDialog',
  components: { CategoryMain },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      model: 0,
    }
  },
  methods: {},
})
