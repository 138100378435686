var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fixed-bar-detail-tab pt-8 pb-10"},[_c('Slider',{attrs:{"list":_vm.targetTabList,"itemKey":"categoryName","activeKey":"categoryName","sliderInName":"chip","differentItemClass":['pl-5', 'pl-3', 'pr-10'],"itemClass":"rounded-0 font-g12-16","itemActiveClass":[
      'rixgo-bold black white--text',
      'rixgo-semibold gray250 gray650--text',
    ],"active":{
      yn: true,
      item: { ..._vm.categoryDetailTab },
    },"centerActive":true},on:{"handleActive":_vm.detailTabChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }