
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { adOnTracking } from '@/plugins/util'
import { PostAdOnClick, PostAdOnView } from '@/api/displayAPI/DisplayAPI'
import { oneDepthTracking, onDepthGroupTracking } from '@/util/category'
import SeasonHotItem from '@/components/common/widgets/SeasonHotItem.vue'
import { getMainPanel, setMainPanel } from '@/helper/category'
import { onRequestAdonBanner } from '@/helper/banner'

export default Vue.extend<Data, Method, Computed, Props>({
  name: 'CategoryMainView',
  components: { SeasonHotItem },
  props: {
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentCategoryGroup: 0,
    panels: [],
    isFirstClickEtcGroup: false,
  }),
  watch: {
    categoryRefresh() {
      this.initCategoryGroup()
      this.setCategoryGroup()
    },
  },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapState('BannerStore', ['onBanner']),
    ...mapState('CategoryStore', ['categoryRefresh', 'history']),
    ...mapGetters('CategoryStore', [
      'getCategoryItemGroup',
      'getCategoryBottomBannerGroup',
      'getSeasonHotItemGroup',
      'getCategoryCode',
    ]),
    ...mapGetters('RouteHistoryStore', ['getScrollTopMap']),

    adBanners() {
      const { bannerItemList } = this.getCategoryBottomBannerGroup

      if (!bannerItemList) return []
      return this.lodash.cloneDeep(bannerItemList)
    },
    targetCategoryName() {
      return this.$route.query.categoryName
    },
    metaCategory() {
      return ['ALL', 'CLOTHES', 'BAG · SHOES · ETC']
    },
  },
  created() {
    this.setCategoryGroup()
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('CategoryStore', [
      'fetchCategoryMain',
      'fetchCategoryHistory',
    ]),
    ...mapActions('BannerStore', ['fetchOnBanner']),

    getBtnDisableClass(condition: boolean): string {
      return condition ? 'blue--text' : 'gray900--text'
    },
    goPage(item: ThemeCategory) {
      this.initCategoryGroup()
      //같은 카테고리일때 페이지이동 skip
      if (this.targetCategoryName === item.title) return
      this.$tracking(item.trackingTitle)

      if (item.title === '공지사항') {
        const url = this.createNoticeUrl()
        const payload = {
          url,
          title: '공지사항',
          isGoneBottomView: true,
        }
        localStorage.setItem('IS_VIEW_NOTICE', 'true')
        if (!this.deviceInfo) {
          this.$router.push('mypageBoard')
          return
        }

        this.$rendingOutsidePage(this.$device, payload)

        return
      }

      this.$router.push({
        path: item.link,
        query: {
          categoryName: item.title,
          previousPage: 'categoryMain',
        },
      })
    },
    goHotLinkPage({ categoryCode, categoryName }, index) {
      this.$tracking(`cate_seasonhot_${this.$numbering(index + 1)}`)

      this.$router.push({
        name: 'CategorySeasonList',
        query: {
          categoryCode,
          categoryName,
          previousPage: 'categoryMain',
        },
      })
    },
    handleCategory(categoryName: string, index: number) {
      setMainPanel({ panel: index })
      this.$tracking(oneDepthTracking[categoryName])
    },
    goCategoryPage(categoryCode: string, categoryName: string) {
      //같은 카테고리일때 페이지이동 skip
      if (categoryCode === this.getCategoryCode && this.isDialog) return
      this.$tracking(`cate_${categoryCode}`)

      this.$router.push({
        name: 'CategoryProductList',
        query: {
          categoryCode,
          categoryName,
          previousPage: 'categoryMain',
        },
      })
    },
    goBannerPage(link, index) {
      const { bannerClickUrl } = this.adBanners[index]

      adOnTracking(bannerClickUrl, (key) => PostAdOnClick({ key }))
      this.$tracking(`banner_cate_${this.$numbering(index + 1)}`)

      const payload = {
        url: link + '&previousPage=categoryMain',
        title: 'CELLOOK',
        isGoneBottomView: false,
      }
      this.$rendingOutsidePageOrInternal(this.$router, this.$device, payload)
    },
    async fetch() {
      try {
        this.fetchLoading({ yn: true })
        await this.fetchCategoryMain()
      } catch (error) {
        console.log(error)
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
    handleRefresh() {
      this.fetch()
      if (this.$route.name) onRequestAdonBanner(this.$route.name)
      this.initCategoryGroup()
      this.setCategoryGroup()
    },
    onIntersect(entries, index) {
      if (entries[0].intersectionRatio < 0.99) return

      const { isLoaded, bannerViewEventUrl } = this.adBanners[index]
      if (!isLoaded) return

      adOnTracking(bannerViewEventUrl, (key) => PostAdOnView({ key }))
      console.log(`카테고리 하단배너 ADON View 트래킹`)
    },
    bannerImgLoad(loadParam: object | string) {
      const bannerItem = this.adBanners.find((value) => {
        return value.icon === loadParam
      })

      if (bannerItem) {
        bannerItem.isLoaded = true
      }
    },
    changeCategoryGroup(item: string, index: number) {
      this.$tracking(onDepthGroupTracking[index])
      if (index === 0) {
        this.goCategoryPage('7', item)
        return
      }

      setMainPanel({ group: index })

      //BAG.. 그룹 최초클릭시 백 카테고리 자동열림
      if (!this.isFirstClickEtcGroup && index === 2) {
        this.panels[2] = 0
        setMainPanel({ panel: 0 })
        this.isFirstClickEtcGroup = true
      }

      this.currentCategoryGroup = index
    },
    initCategoryGroup() {
      this.currentCategoryGroup = 1
      this.panels = []
      setMainPanel({ group: 1, panel: -1 })
      this.fetchCategoryHistory({
        page: { categoryCode: '7', categoryName: '전체' },
      })
    },
    async setCategoryGroup() {
      const { group, panel } = await getMainPanel()
      this.currentCategoryGroup = group
      this.isFirstClickEtcGroup = false
      this.panels[group] = panel

      this.fetchOnBanner({ onBanner: true })

      const history = this.getScrollTopMap

      this.$nextTick(() => {
        this.$getAppHtml.scrollTo({
          top: history['/categoryMain'] ?? 0,
        })
      })
    },
    createNoticeUrl() {
      const envPrefix = process.env.VUE_APP_ENV === 'production' ? '' : '-alpha'
      const appSuffix = this.deviceInfo !== null ? '?mobileApp=true' : ''
      return `https://intro${envPrefix}.cellook.kr/notice${appSuffix}&referrer=cellook`
    },
  },
})

interface Data {
  currentCategoryGroup: number
  panels: number[]
  isFirstClickEtcGroup: boolean
}
interface Method {
  // Store
  fetchLoading(payload: { yn: boolean }): Promise<void>
  fetchCategoryMain(): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  // Component
  fetch(): void
  goPage(item: ThemeCategory): void
  goBannerPage(link: string, index: number): void
  goHotLinkPage(item: any, index: number): void
  goCategoryPage(categoryCode: string, categoryName: string): void
  onIntersect(entries: IntersectionObserverEntry[], index: number): void
  handleRefresh(): void
  bannerImgLoad(loadParam: object | string): void
  changeCategoryGroup(item: string, index: number): void
  getBtnDisableClass(condition: boolean, test: any): string
  setCategoryGroup(): void
  initCategoryGroup(): void
  handleCategory(categoryName: string, index: number): void
  createNoticeUrl(): string

  fetchOnBanner(payload: { onBanner: boolean }): void
}
interface Computed {
  getCategoryItemGroup: CategoryItemGroup[]
  getSeasonHotItemGroup: CategorySeason
  getCategoryBottomBannerGroup: CategoryBannerList
  adBanners: AdonBanner[]
  onBanner: boolean
  targetCategoryName: string | (string | null)[]
  getCategoryCode: string
  history: CategoryHistory
  metaCategory: string[]
  deviceInfo: DeviceInfo | null
  getScrollTopMap: {
    [key: string]: number | undefined
  }
}
interface Props {
  isDialog: boolean
}
