
import ProductCardY from '../../../common/widgets/ProductCardY.vue'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
export default Vue.extend<Data, Method, Computed, Props>({
  name: 'CategoryProduct',
  components: { ProductCardY },
  computed: {
    ...mapGetters('CategoryStore', ['getScrollLocation']),
  },
  props: {
    chipsArr: {
      type: Array as () => string[],
      default: () => [],
    },
    detailFilterTargetPage: {
      type: Boolean,
      default: () => false,
    },
    totalCount: {
      type: Boolean,
      default: () => true,
    },
    searchProductList: {
      type: Array,
      default: () => [],
    },
    totalPageCount: {
      type: Object,
      default: () => ({
        total: 0,
        page: 0,
        size: 0,
      }),
    },
    gtagName: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('CategoryStore', [
      'fetchScrollLocation',
      'fetchMarginHeight',
      'fetchSearchProducts',
    ]),
    resetDetailFilter() {
      this.$emit('resetDetailFilter')
    },
    onRequest(page, type) {
      this.$emit('onPageScrollEvent', page, type)
    },
    isLikeChange(yn: boolean, index: number) {
      //카테고리 리스트 좋아요 반영
      const copy = [...this.searchProductList]
      copy[index].isLike = yn
      this.fetchSearchProducts(copy)
      //GA 트래킹
      if (!this.gtagName) return
      const trackingName = `${this.gtagName}_${this.$numbering(index + 1)}`
      if (yn) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
  },
})
interface Data {}
interface Method {
  resetDetailFilter(): void
  fetchScrollLocation(scrollLocation: number): void
  onRequest(page: number, type: string): void
  fetchMarginHeight(payload: number): void
  isLikeChange(yn: boolean, index: number): void
  fetchSearchProducts(payload: ProductItem[]): Promise<void>
}
interface Computed {}
interface Props {
  chipsArr: Object
  detailFilterTargetPage: boolean
  totalCount: boolean
  searchProductList: ProductItem[]
  totalPageCount: { total: number; page: number; size: number }
  gtagName: string
}
