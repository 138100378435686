
import Vue from 'vue'
export default Vue.extend<Data, Computed, Methods, Props>({
  name: 'SeasonHotItem',
  props: {
    image: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleActive() {
      this.$emit('handleActive')
    },
  },
})

interface Data {}
interface Computed {}
interface Methods {}
interface Props {
  image: string
  text: string
}
