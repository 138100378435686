var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PullToRefresh',{staticClass:"pb-64",attrs:{"popupPath":"categoryMain","prevent":_vm.isDialog},on:{"handleRefresh":_vm.handleRefresh}},[_c('div',[_c('div',{staticClass:"d-flex flex-column overflow-x-auto pt-12 pb-14 border-bottom-gray"},[_c('span',{staticClass:"font-g16-24 rixgo-bold gray900--text ml-10"},[_vm._v("시즌 핫템")]),_c('div',{staticClass:"d-flex overflow-x-auto mt-8 pl-8 pr-5"},_vm._l((_vm.getSeasonHotItemGroup.seasonHotItemList),function(item,index){return _c('SeasonHotItem',{key:index,attrs:{"image":item.categoryImage,"text":_vm._f("truncate")(item.categoryName)},nativeOn:{"click":function($event){return _vm.goHotLinkPage(item, index)}}})}),1)]),_c('div',[_c('div',{staticClass:"px-10 pt-14"},[_vm._l((_vm.metaCategory),function(group,index){return _c('div',{key:index,class:`d-inline-block font-g16-24 campton-semibold mr-10 ${
            _vm.currentCategoryGroup === index
              ? 'gray900--text border-bottom-2'
              : 'gray500--text'
          }`,on:{"click":function($event){return _vm.changeCategoryGroup(group, index)}}},[_c('span',[_vm._v(_vm._s(group))])])}),_c('v-expansion-panels',{staticClass:"mt-11",attrs:{"accordion":"","flat":""},model:{value:(_vm.panels[_vm.currentCategoryGroup]),callback:function ($$v) {_vm.$set(_vm.panels, _vm.currentCategoryGroup, $$v)},expression:"panels[currentCategoryGroup]"}},_vm._l((_vm.getCategoryItemGroup[_vm.currentCategoryGroup]
              .categoryList),function(item,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{on:{"click":function($event){return _vm.handleCategory(item.categoryName, index)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$vuetify.icons.chevronDownGrayIcon")])]},proxy:true}],null,true)},[_c('span',{staticClass:"font-g14-20 rixgo-semibold gray900--text"},[_vm._v(_vm._s(item.categoryName))])]),_c('v-expansion-panel-content',{staticClass:"mx-4"},[_c('div',{staticClass:"fill-width d-inline-flex flex-row align-start flex-wrap px-8 py-4 gray100 border-radius-4"},_vm._l((item.categoryList),function(subCategory,subCategoryIndex){return _c('span',{key:subCategoryIndex,class:`d-inline-block font-g12-18 rixgo-semibold ${_vm.getBtnDisableClass(
                    _vm.getCategoryCode === subCategory.categoryCode
                  )} text-left py-5`,staticStyle:{"width":"50%"},on:{"click":function($event){return _vm.goCategoryPage(
                      subCategory.categoryCode,
                      item.categoryName
                    )}}},[_vm._v(_vm._s(subCategory.categoryName))])}),0)])],1)}),1)],2),_c('div',{staticClass:"fill-width border-bottom-gray my-10"}),_c('div',{staticClass:"fill-width d-inline-flex align-start flex-wrap"},_vm._l((_vm.history.category.themeItemGroup
            .themeItemList),function(item,index){return _c('span',{key:index,staticClass:"d-inline-block font-g16-24 rixgo-extrabold gray900--text pr-10 py-6",style:(`width: 50%;padding-left:${index % 2 !== 0 ? 0 : 20}px`),on:{"click":function($event){return _vm.goPage(item)}}},[_vm._v(_vm._s(item.title))])}),0)]),(_vm.adBanners.length > 0)?_c('div',{staticClass:"mt-14 ml-10 mr-10"},_vm._l((_vm.adBanners),function(item,index){return _c('v-img',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: (e) => _vm.onIntersect(e, index),
          options: {
            rootMargin: '0px 0px -75px 0px',
            threshold: [0, 0.99, 1.0],
          },
        }),expression:"{\n          handler: (e) => onIntersect(e, index),\n          options: {\n            rootMargin: '0px 0px -75px 0px',\n            threshold: [0, 0.99, 1.0],\n          },\n        }"}],key:index,staticClass:"mt-3 border-radius-8",attrs:{"eager":true,"src":item.icon,"lazy-src":require('@/assets/images/img_back.png'),"aspect-ratio":335 / 98},on:{"click":function($event){return _vm.goBannerPage(item.link, index)},"load":_vm.bannerImgLoad}})}),1):_c('div',{staticClass:"mt-10"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }