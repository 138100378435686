
import Vue from 'vue'
import Slider from '../../../common/slider/Slider.vue'

export default Vue.extend({
  name: 'CategoryDetailTab',
  components: {
    Slider,
  },
  props: {
    targetTabList: {
      type: Array,
      default: () => [],
    },
    categoryDetailTab: {
      type: Object,
      default: () => Object,
    },
  },
  data() {
    return {}
  },
  methods: {
    detailTabChange(detailTabData: CategoryItem, index: number) {
      this.$emit('detailTabChange', detailTabData, index)
    },
  },
})
