
import Vue from 'vue'
export default Vue.extend({
  name: 'CategoryTab',
  props: {
    targetTabList: {
      type: [Object, Array],
      default: () => Object,
    },
    categoryTab: {
      type: Number,
      default: () => 0,
    },
    color: {
      type: String,
      default: 'default',
    },
    backgroundColor: {
      type: String,
      default: 'gray900',
    },
    sliderColor: {
      type: String,
      default: 'white',
    },
    sliderSize: {
      type: String,
      default: '1',
    },
    sliderBottom: {
      type: String,
      default: '10px',
    },
    /**
     * 탭 높이
     */
    height: {
      type: String,
      default: '44px',
    },
    /**
     * tab이 선택되었을 때 적용될 클래스
     */
    tabActiveClass: {
      type: String,
      default: 'rixgo-extrabold white--text',
    },
  },
  computed: {
    cssProps() {
      return {
        '--slider-bottom': this.sliderBottom,
      }
    },
    tab: {
      get() {
        return 'tab-' + this.categoryTab
      },
      set(value) {
        //no-op
      },
    },
  },
  methods: {
    categoryTabChange(tabData: Object, index: number) {
      this.$emit('categoryTabChange', tabData, index)
    },
  },
})
