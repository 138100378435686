
import Vue from 'vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import CategoryHeader from '@/components/views/category/common/CategoryHeader.vue'
import CategoryTab from '@/components/views/category/common/CategoryTab.vue'
import CategoryDetailTab from '@/components/views/category/common/CategoryDetailTab.vue'
import SaleProductView from '@/components/views/category/common/SaleProductView.vue'
import CategoryProduct from '@/components/views/category/common/CategoryProduct.vue'
import { GetCategoryNewArrivals } from '@/api/displayAPI/CategoryAPI'
import { AppProfile } from 'types/App'

const PAGE_LIMIT = 50
export default Vue.extend<Data, Methods, Computed>({
  name: 'CategoryNewListView',
  components: {
    CategoryHeader,
    CategoryTab,
    CategoryDetailTab,
    SaleProductView,
    CategoryProduct,
  },
  data() {
    return {
      dataFetchComplete: false,
      showDetailTab: false,
      handleAnimation: false,
      flag: false,
    }
  },
  computed: {
    ...mapGetters('ProfileStore', ['getProfile']),
    ...mapGetters('CategoryStore', ['getCategoryHistory']),
    ...mapState('CommonStore', ['scrollTopNoti']),
    categoryList() {
      if (!this.getProfile?.category?.categoryList) {
        return []
      }

      return this.getProfile.category.categoryList
    },
    history() {
      return this.getCategoryHistory
    },
  },
  async created() {
    this.$getAppHtml.addEventListener('scroll', this.handleScroll)
    this.fetchCategoryHistory({
      page: { currentPage: this.$route.path.slice(1) },
    })

    const { searchList } = this.history.page
    if (!searchList.length) {
      this.initFetch()
    } else {
      this.dataFetchComplete = true
    }
  },
  destroyed() {
    this.$getAppHtml.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    scrollTopNoti() {
      this.$tracking('cate_new_go_to_top')
    },
    async isLogined() {
      try {
        this.fetchLoading({ yn: true })
        const initFirstPage = this.history.page.searchList[0].groupKey ?? 0
        const data = await Promise.all([
          this.getListData(initFirstPage),
          this.getListData(initFirstPage + 1),
          this.getListData(initFirstPage + 2),
        ])

        const list = await this.$infiniteRefreshList(data, initFirstPage)
        this.fetchCategoryHistory({
          page: {
            searchList: list,
          },
        })
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('CategoryStore', ['fetchCategoryHistory']),
    async fetch(type = 'reset') {
      try {
        const {
          categoryCode,
          categoryDetailTab,
          paging,
          isSaleProduct,
          searchList,
        } = this.history.page

        const { page } = paging
        const childYn = categoryDetailTab.categoryCode === '7'

        const params = {
          categoryCode: childYn ? categoryCode : categoryDetailTab.categoryCode,
          page,
          limit: PAGE_LIMIT,
          isSaleCheck: isSaleProduct,
        }

        await GetCategoryNewArrivals(params).then(({ data }) => {
          const replaceList = this.$replaceList(
            searchList,
            data.searchProductList,
            data.paging.page,
            PAGE_LIMIT,
            type
          )

          this.fetchCategoryHistory({
            page: {
              paging: data.paging,
              searchList: replaceList,
            },
          })
        })
      } catch (e) {
        console.log('category new arrivals error')
      }
    },
    async initFetch() {
      this.fetchLoading({ yn: true })
      try {
        this.dataFetchComplete = false
        await this.fetch()
      } finally {
        this.dataFetchComplete = true
        this.fetchLoading({ yn: false })

        const { categoryTab, categoryDetailTab, searchList } = this.history.page

        // criteo
        if (this.categoryList.length > 0) {
          const { categoryName } = this.categoryList[categoryTab]
          let criteoCategory =
            categoryName === '전체'
              ? categoryName
              : `${categoryName}/${categoryDetailTab.categoryName}`
          let criteoItems = searchList.slice(0, 3).map((item) => item.productNo)
          this.$criteo({
            event: 'viewList',
            category: criteoCategory,
            item: criteoItems,
          })
        }
      }
    },
    handleScroll() {
      if (this.handleAnimation) {
        if (this.$getAppHtml.scrollTop < 105) {
          this.handleAnimation = false
        }
      } else {
        if (this.$getAppHtml.scrollTop > 100) {
          this.handleAnimation = true
        }
      }
    },
    resetList() {
      this.fetchCategoryHistory({
        page: {
          searchList: [],
          marginHeight: 0,
        },
      })
    },
    categoryTabChange({ categoryCode, categoryName, categoryList }, index) {
      this.$tracking(`cate_new_cateid_${categoryCode}`)
      this.$getAppHtml.scrollTo({ top: 0 })
      this.resetList()

      const childYn = categoryList.length > 0
      this.fetchCategoryHistory({
        page: {
          categoryTab: index,
          categoryCode,
          categoryName,
          categoryDetailTab: {
            categoryCode: childYn ? categoryList[0].categoryCode : '7',
            categoryName: childYn ? categoryList[0].categoryName : '전체',
          },
        },
      })

      this.refreshPaging()
      this.initFetch()
    },
    detailTabChange({ categoryName, categoryCode }) {
      const { categoryDetailTab } = this.history.page
      if (categoryName === categoryDetailTab.categoryName) return
      this.$tracking(`cate_new_cateid_${categoryCode}`)
      this.resetList()

      this.fetchCategoryHistory({
        page: {
          categoryDetailTab: { categoryName, categoryCode },
        },
      })

      this.refreshPaging()
      this.initFetch()
    },
    saleCheckTabChange(index) {
      this.$getAppHtml.scrollTo({ top: 0 })
      this.$tracking(`cate_new_saleonly`)
      this.resetList()

      this.fetchCategoryHistory({
        page: {
          isSaleProduct: index,
        },
      })
      this.refreshPaging()
      this.initFetch()
    },
    async onPageScrollEvent(page, type) {
      if (this.flag) return
      this.flag = true
      const { paging } = this.history.page
      this.fetchCategoryHistory({
        page: { paging: { ...paging, page } },
      })

      await this.fetch(type)
      this.flag = false
    },
    refreshPaging() {
      this.fetchCategoryHistory({
        page: { paging: { page: 0, limit: 50, total: 0 } },
      })
    },
    onClickTriangle(flag) {
      if (flag) {
        this.handleAnimation = false
      } else {
        if (this.$getAppHtml.scrollTop > 100) {
          this.handleAnimation = true
        }
      }
    },
    handleRefresh() {
      // TODO - pull :  중복 코드 : 확인 부탁드립니다 to. 칼
      this.fetchCategoryHistory({
        page: {
          categoryDetailTab: { categoryName: '전체', categoryCode: '7' },
          isSaleProduct: 0,
        },
      })
      this.initFetch()
    },
    onClickPrev() {
      this.$tracking(`cate_new_back`)
    },
    onClickTitle() {
      this.$tracking(`cate_new_back_to_cate`)
    },
    async getListData(page) {
      try {
        const { categoryCode, categoryDetailTab, isSaleProduct } =
          this.history.page

        const childYn = categoryDetailTab.categoryCode === '7'

        const params = {
          categoryCode: childYn ? categoryCode : categoryDetailTab.categoryCode,
          page,
          limit: PAGE_LIMIT,
          isSaleCheck: isSaleProduct,
        }
        const { data } = await GetCategoryNewArrivals(params)
        return data.searchProductList
      } catch (e) {
        return []
      }
    },
  },
})

interface Data {
  dataFetchComplete: boolean
  showDetailTab: boolean
  handleAnimation: boolean
  flag: boolean
}
interface Methods {
  //Store
  fetchLoading(payload: { yn: boolean }): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  // Component
  fetch(type?: string): void
  initFetch(): void
  handleScroll(): void
  resetList(): void
  categoryTabChange(item: CategoryItem, index: number): void
  detailTabChange(item: CategoryItem): void
  saleCheckTabChange(index: number): void
  onPageScrollEvent(page: number, type: string): void
  refreshPaging(): void
  onClickTriangle(flag: boolean): void
  handleRefresh(): void
  onClickPrev(): void
  onClickTitle(): void
  getListData(page: number): Promise<ProductItem[]>
}
interface Computed {
  getProfile: AppProfile
  getCategoryHistory: CategoryHistory
  scrollTopNoti: boolean
  categoryList: CategoryItem[]
  history: CategoryHistory
}
