
import Vue from 'vue'
export default Vue.extend({
  name: 'SaleProductView',
  components: {},
  props: {
    isSaleProduct: {
      type: Boolean as () => boolean,
      default: () => true,
    },
  },
  methods: {
    saleCheckTabChange() {
      this.$emit('saleCheckTabChange', this.isSaleProduct ? 1 : 0)
    },
  },
})
