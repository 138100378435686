export const getHistory = () => {
  const getLocalStorage = localStorage.getItem('categoryHistory')
  const getHistory: CategoryHistory | null = getLocalStorage
    ? JSON.parse(Object(getLocalStorage))
    : null
  localStorage.removeItem('categoryHistory')
  return getHistory
}

interface PanelInfo {
  group: number
  panel: number
}
interface PanelInfoParam {
  group?: number
  panel?: number
}

export const getMainPanel = (): PanelInfo => {
  const defaultData: string = JSON.stringify({ group: 1, panel: -1 })
  const data: PanelInfo = JSON.parse(
    sessionStorage.getItem('panelInfo') ?? defaultData
  )

  return data
}

export const setMainPanel = (panelInfo: PanelInfoParam) => {
  let data = getMainPanel()
  data = { ...data, ...panelInfo }
  sessionStorage.setItem('panelInfo', JSON.stringify(data))
}
